import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { CaseStudyCtaGoTo } from "./generative-ai-itsm";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");
const header_image = require("../../../assets/img/blogs/essc_blog_header.png");
const header_image_mobile = require("../../../assets/img/blogs/essc_blog__header_mob.png");
const section_1 = require("../../../assets/img/blogs/essc_blog_image_1.png");
const section_2 = require("../../../assets/img/blogs/essc_blog_image_2.png");
const section_3 = require("../../../assets/img/blogs/essc_blog_image_3.png");
const section_4 = require("../../../assets/img/blogs/essc_blog_image_4.png");
const section_5 = require("../../../assets/img/blogs/essc_blog_image_5.png");
const section_6 = require("../../../assets/img/blogs/essc_blog_image_6.png");
const section_7 = require("../../../assets/img/blogs/essc_blog_image_7.png");
const section_8 = require("../../../assets/img/blogs/essc_blog_image_8.png");
const section_9 = require("../../../assets/img/blogs/essc_blog_image_9.png");
const section_10 = require("../../../assets/img/blogs/essc_blog_image_10.png");
const section_11 = require("../../../assets/img/blogs/essc_blog_image_11.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  const backgroundImage = isSmall ? header_image_mobile : header_image;
  return (
    <>
      <TitleAndMetaTags
        title="Employee self-service: Private ChatGPT for auto-resolution"
        description="A private ChatGPT gives tailor-made solutions for your service desk and empowers your employees to solve problems at scale. Get Knowledge AI for custom responses."
        keywords={[
          "ai for itsm, ai itsm, itsm, itsm ai, ai in itsm, ai service management, artificial intelligence service management",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section
            className={`w-100 float-left blog_top_header_upt striking_blog_header_upt ${
              isSmall ? "align-items-start" : null
            }`}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile ? "text-align-center" : "text-align-left"
                }`}
              >
                {" "}
                {isMobile ? (
                  <h1 className="font-page-header-home-blog color-black">
                    The Power of Self-Service: Empowering Employees with a
                    Private ChatGPT
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog color-black">
                    The Power of Self-Service:
                    <br /> Empowering Employees
                    <br /> with a Private ChatGPT
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}
          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. What is a private ChatGPT?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. What is self-service for employee support?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. What is a private ChatGPT for self-service?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. How can you leverage the ability of a private ChatGPT for
                  employee self-service?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. How can a private ChatGPT help with self-service?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. How does Workativ help you build a private ChatGPT?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. How does Workativ empower employees with a private ChatGPT?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section8"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section8"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section8")}
                >
                  8. Workativ X Knowledge AI: Powerful RAG systems for your
                  private ChatGPT needs
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section9"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section9"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section9")}
                >
                  9. FAQs
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://www.youtube.com/watch?v=04Vqq45hPOA">
                  ChatGPT
                </a>{" "}
                is extremely powerful when it comes to increasing employee
                support productivity through self-service.
              </p>{" "}
              <p className="font-section-normal-text-testimonials line-height-18">
                If we look into its fundamental capabilities, ChatGPT can help
                beef up{" "}
                <a href="https://workativ.com/conversational-ai-platform/ai-self-service-chatbot">
                  self-service for employee support
                </a>{" "}
                by
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Generating answers for common queries from the world of
                  knowledge
                </li>
                <li className="font-section-normal-text-testimonials">
                  Crafting responses using NLP prompts
                </li>
                <li className="font-section-normal-text-testimonials">
                  Simplifying the understanding of critical support queries
                </li>
                <li className="font-section-normal-text-testimonials">
                  Creating data analytics reports
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                The only setback is the outcome, which is quite generic if you
                work with the public version of ChatGPT.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, you can easily get the benefits that of ChatGPT while
                enjoying more personalized capacity and security by leveraging a
                Private ChatGPT.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With Private ChatGPT, you can easily elevate the typical
                self-service capacity for employee support and empower your
                employees to work autonomously.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                You already learned about ChatGPT—but Private ChatGPT may seem
                new to you.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We’ll learn in this article,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  What is a private ChatGPT?
                </li>
                <li className="font-section-normal-text-testimonials">
                  How can a private ChatGPT help boost self-service for employee
                  support?
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Keep reading, and we’ll explore more sides of a private ChatGPT
                for self-service.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is a private ChatGPT?
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="Private GPT or ChatGPT for employee support "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                A private ChatGPT is a GPT-based chatbot or chat interface that
                exhibits ChatGPT-like qualities to provide personal or
                tailor-made solutions to users unavailable with public
                solutions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                More about a private ChatGPT—
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Private ChatGPT leverages LLMs trained on internal knowledge
                  repositories or corpora of datasets.
                </li>
                <li className="font-section-normal-text-testimonials">
                  It is designed with{" "}
                  <a href="https://workativ.com/security">
                    critical security features.
                  </a>
                </li>
                <li className="font-section-normal-text-testimonials">
                  It can generate domain-specific answers and solve problems at
                  scale, including general answers.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Check the image below for an example:
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="domain-specific answers for employees on Private GPT or ChatGPT"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                When asked about the salary breakup, a private ChatGPT can give
                you straightforward answers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is because a private ChatGPT has access to internal HR
                systems and other company-wide systems that help retrieve
                specific answers.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="answers for traditional search on off-the-shelf GPT interfaces"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                On the other hand, if you ask the public version of ChatGPT,
                which has cut-off knowledge of up to September 2021, you can
                only get results similar to those of a search engine.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It means you need to provide all the critical data—a manual
                effort that is time-consuming and irritating.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Usually, your employees can call your HR to get full details.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is where a private ChatGPT comes in and provides
                tailor-made solutions to reduce manual dependency and
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-automation-virtual-employee-service-desk">
                  {" "}
                  increase self-service capability
                </a>
                , empowering your employees.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is self-service for employee support?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Self-service is an autonomous capability for customer or
                employee support. It allows users or employees to access
                knowledge bases or predefined FAQs to seek answers to their
                specific questions and solve problems independently.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The key objective of the self-service chatbot for employee
                support is to —
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Enable knowledge discovery
                </li>
                <li className="font-section-normal-text-testimonials">
                  Free agents of repetitive tasks
                </li>
                <li className="font-section-normal-text-testimonials">
                  Solve common problems at scale
                </li>
                <li className="font-section-normal-text-testimonials">
                  Reduce MTTR
                </li>
                <li className="font-section-normal-text-testimonials">
                  Reduce agent dependency
                </li>
                <li className="font-section-normal-text-testimonials">
                  Improve ROI
                </li>
                <li className="font-section-normal-text-testimonials">
                  Drive cost efficiency
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, self-service built with predefined knowledge articles
                or FAQs can often fall short of employee support expectations.
                As a result, the shift-left strategy may take a big hit. This
                would mean agent involvement would rise, and tickets would
                remain in the queue only to impact user experiences.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In contrast, a private ChatGPT can empower your employees and
                boost collaboration and efficiency for self-service.{" "}
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is a private ChatGPT for self-service?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Turning your internal knowledge bases or articles into
                ChatGPT-like abilities with the power of LLM that helps you
                generate Generative AI responses for user queries and solve
                problems autonomously is a private ChatGPT for self-service.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With a private ChatGPT, you extend the automation of
                self-service and gain more capabilities than the public version.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A private ChatGPT is effective in several ways across
                self-service due to the following reasons —
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Unique responses for new ITSM scenarios
                </li>
                <li className="font-section-normal-text-testimonials">
                  Faster NLP processing to enable rapid knowledge discovery
                </li>
                <li className="font-section-normal-text-testimonials">
                  Domain-specific responses to address company-wide issues
                </li>
                <li className="font-section-normal-text-testimonials">
                  Intelligent fallback for agent handover
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                A private ChatGPT can be very helpful for any business looking
                to leverage personalized or tailor-made solutions.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How can you leverage the ability of a private ChatGPT for
                employee self-service?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                The perspective is that if your user asks a question,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-future-enterprise-knowledge-search">
                  your LLM
                </a>{" "}
                must contain relevant document text to answer it.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With any public version of the GPT model or ChatGPT, you can
                only access the world of knowledge or the Internet for a certain
                period.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A generic answer may not suffice. Employees need relevant
                answers to address a specific challenge related to HR or IT.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Integrating your LLM models or ChatGPT with the RAG system
                easily allows you to gain the capability of a private ChatGPT.
              </p>

              <h3 className="font-section-sub-header-small-home">
                What is RAG?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                RAG is an approach that allows users to search on a third-party
                resource within a Generative AI environment yet ensures the
                relevancy, accuracy, and context of an answer.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/rag-user-support">
                  Retrieval Augmented Generation, or RAG,
                </a>{" "}
                gives LLM the additional power to retrieve relevant answers for
                a question input through a semantic search.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It helps provide personalized responses and improves
                self-service adoption and engagement for employee support.
              </p>

              <h3 className="font-section-sub-header-small-home">
                How does RAG work to build a private ChatGPT?
              </h3>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="Private GPT built on RAG for employee support "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                RAG or Retrieval Augmented Generation can work in the following
                ways:
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  RAG allows building a{" "}
                  <a href="https://workativ.com/conversational-ai-platform/conversational-ai">
                    hybrid NLU system,
                  </a>{" "}
                  making it easy for the Generative AI interface to augment LLM
                  searches by pulling up information from existing LLM databases
                  and third-party or external resources.
                </li>
                <li className="font-section-normal-text-testimonials">
                  RAG needs LLM to use vector databases to break down inputs
                  into embeddings or numeric representations.
                </li>
                <li className="font-section-normal-text-testimonials">
                  LLM searches look for answers from the LLM databases.
                </li>
                <li className="font-section-normal-text-testimonials">
                  When not found, RAG helps with semantic search and retrieves
                  custom responses from external sources for LLM.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                When integrated with RAG or hybrid NLU, you can transform the
                public version of GPT or ChatGPT into a private ChatGPT to
                address unique self-service problems and reduce{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/helpdesk-problems-solutions">
                  service desk challenges.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How can a private ChatGPT help with self-service?
              </h2>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="Private GPT vs. traditional chatbots"
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                Unlike ChatGPT or over-the-shelf GPT models, a private ChatGPT
                is designed to answer every unique question and elevate agent
                performance through enhanced productivity and efficiency.
              </p>
              <h3 className="font-section-sub-header-small-home">
                1. Contextual responses
              </h3>

              <p class="font-section-normal-text-testimonials line-height-18">
                Suppose you use a typical chatbot for common questions and
                answers. You can get answers based on keyword matching. This can
                create a negative user experience, for pre-defined answers can
                be repeated.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                A private ChatGPT uses RAG to leverage semantic search. This
                helps find information based on user intent in the LLM articles
                and external databases, boosting the relevance of responses.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                So, if your employee asks, “This is not the right laptop?”. A
                private ChatGPT fetches information from ERP systems and
                provides a satisfactory answer.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="Private GPT to improve fulfillment delivery for agents in service desk "
              />
              <h3 className="font-section-sub-header-small-home">
                2. Consolidated responses
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                What happens with a regular chatbot is that it fetches an entire
                article only to increase your effort. You must read the entire
                article, find the answer, and solve a problem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A private ChatGPT can access the proprietary data repository and
                inherent LLM summarization capability. It can give relevant
                answers to company-specific problems and reduce unnecessary
                efforts required to pull the right answer for a specific problem
                through the summarization capability.
              </p>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="Private GPT to simplify coordination and responses "
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                If your employee seeks help fixing a printer problem with a
                particular company product, a private ChatGPT can instantly
                assist.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Your employee can get summarized tips, including an article that
                contains all critical information about fixing a printer of a
                particular model.
              </p>
              <h3 className="font-section-sub-header-small-home">
                3. Personalized responses
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Ask a chatbot or ChatGPT about personal questions. It rarely can
                give answers. It can repeat ‘rephrase your queries ‘ or give a
                generic answer.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                However, a private GPT or ChatGPT can make it quite easy for
                your employees and increase their satisfaction by providing
                personalized responses.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                As RAG provides LLM searches using third-party resources or
                hybrid NLU, every employee can get personalized answers.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                For example, if you ask for benefits enrollment, it can give
                concise and personalized answers without providing an answer
                that requires further calculations.
              </p>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="personalized responses on Private GPT or ChatGPT for employee support "
              />
              <h3 className="font-section-sub-header-small-home">
                4. AI suggestions
              </h3>
              <img
                src={section_9}
                className="blog_image_top_bt"
                alt="Private GPT makes response generation easy "
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                Service desk agents can have extended flexibility and automation
                to accelerate the pace of responses. With a typical chatbot,
                employee support can suffer, as employees need better English
                knowledge to craft a message. This can lead to confusion and a
                vague understanding of the solution.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                A private ChatGPT can learn about internal{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide">
                  ITSM
                </a>{" "}
                interactions and activities, using content generation capability
                to provide accurate and relevant answers.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                For example, suppose an employee gets an agent call regarding
                software installs. In that case, the agent can craft meaningful
                messages and help appropriately despite being new and having
                limited capacity to craft messages.
              </p>
              <h3 className="font-section-sub-header-small-home">
                5. AI summary
              </h3>
              <img
                src={section_10}
                className="blog_image_top_bt"
                alt="Private GPT to provide chat summaries for agents"
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                Agents often craft a summary of an incident for a closed ticket.
                This task may appear challenging for anyone. They often prefer
                overlooking the responsibility, leading to a fiasco when needed
                to pull data and create a report.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                It is easy for agents to craft a summary as a private ChatGPT,
                enabling them to use internal ITSM data for actions to mitigate
                a problem.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                A typical chatbot can lack this feature. Even if a ChatGPT can
                help with summarization, some information may be irrelevant.
              </p>
              <h3 className="font-section-sub-header-small-home">
                6. Ticket triage
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                It is only possible for your internal service desk team to learn
                who is eligible to handle a particle case. When RAG has access
                to that resource, your Private ChatGPT can allocate a particular
                employee support case to the right agent when needed.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                An LLM inside a Generative AI interface uses RAG and LLM to
                build predictive insights and determine who can handle a case
                depending on previous history and actions taken.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                However, a public version of the GPT model cannot work to this
                scale due to limited data exposure, giving only a generic
                solution.
              </p>
              <h3 className="font-section-sub-header-small-home">
                7. Accurate and relevant answers
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Employees are more independent in solving IT and HR-related
                queries rather than choosing to go to agents. The Retrieval
                Augmented Generation, or RAG, helps boost LLM searches and fetch
                relevant answers, which offer intent, context, and
                disambiguation. This can effectively remove hallucinations,
                which are familiar with the public version of the GPT model, as
                they can make up responses in the absence of sufficient data.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Employees can solve problems faster and increase productivity
                with an increased relevancy rate.
              </p>

              <CaseStudyCtaGoTo colorKey="peach" textColor="blue" />
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How does Workativ help you build a private ChatGPT?
              </h2>
              <img
                src={section_11}
                className="blog_image_top_bt"
                alt="Private GPT or ChatGPT for employee experience"
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ
                </a>{" "}
                is known to make employee support seamless with its LLM-powered
                conversational AI platform.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                In addition, Workativ offers an excellent opportunity to build a
                private ChatGPT effortlessly using its Knowledge AI feature.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI
                </a>{" "}
                is similar to RAG systems, allowing you to access third-party
                resources to extend LLM searches.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ is a no-code{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  SaaS-based conversational AI platform
                </a>{" "}
                that can easily be used to build your Private ChatGPT for HR and
                IT support.
              </p>
              <h3 className="font-section-sub-header-bold">
                Follow some simple steps to build your private ChatGPT with
                Workativ:
              </h3>
              <h3 className="font-section-sub-header-small-home">
                Step 1: Use existing conversations to add Knowledge AI
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Choose an existing conversation flow
                </li>
                <li className="font-section-normal-text-testimonials">
                  Click the Arrow sign next to a card
                </li>
                <li className="font-section-normal-text-testimonials">
                  Find a drop-down menu
                </li>
                <li className="font-section-normal-text-testimonials">
                  Select Knowledge AI
                </li>
                <li className="font-section-normal-text-testimonials">
                  Supply details to the box
                </li>
                <li className="font-section-normal-text-testimonials">
                  Save it
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                Your existing chat conversation can leverage the power of
                Knowledge AI or private ChatGPT-like searches and boost employee
                support.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                To learn more about how you can create a Private ChatGPT for
                employee support,{" "}
                <a href="https://workativ.com/contact-us">contact us</a> at
                Workativ.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Step 2: Build Knowledge AI conversations from scratch
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Go to chatbot builder inside Workativ
                </li>
                <li className="font-section-normal-text-testimonials">
                  Select Knowledge AI from the menu header
                </li>
                <li className="font-section-normal-text-testimonials">
                  Select third-party resources, i.e., SharePoint, Drive,
                  DropBox, etc
                </li>
                <li className="font-section-normal-text-testimonials">
                  Supply necessary information and get started
                </li>
                <li className="font-section-normal-text-testimonials">
                  Prepare user query
                </li>
                <li className="font-section-normal-text-testimonials">
                  Customize your workflows
                </li>
                <li className="font-section-normal-text-testimonials">
                  Add human touch
                </li>
                <li className="font-section-normal-text-testimonials">
                  Deploy your Knowledge AI or private ChatGPT bot in MS Teams,
                  Slack, or anywhere
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                Building a private ChatGPT is not hard work. You can use a
                zero-code platform and implement Knowledge AI search for your
                employees.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How does Workativ empower employees with a private ChatGPT?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                As you build your private ChatGPT or Knowledge AI chatbot,
                things can work in your favor, and you will have amazing
                benefits for HR and IT support.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Zero-wait time
              </h3>

              <p class="font-section-normal-text-testimonials line-height-18">
                Private ChatGPT or Knowledge AI for HR chatbot or IT support
                chatbot can boost collaborations and communications by turning
                your knowledge articles into ChatGPT-like responses. Your
                employees can increase their response time for problem
                resolutions and enjoy{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-employee-support-24-7#:~:text=Simultaneously%2C%20Workativ%20is%20the%20right,drive%20towards%20zero%20wait%20time.">
                  zero wait time
                </a>{" "}
                for service desk queries.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Zero-touch service desks
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ’s Knowledge AI or Private ChatGPT empowers employees,
                including service desk agents. With LLM-powered knowledge
                articles, your employees find answers rapidly, seeking agents’
                help in rare cases. Employees autonomously solve their problems,
                freeing agents to drive towards{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-user-support-zero-touch">
                  zero-touch service desks.{" "}
                </a>
              </p>

              <h3 className="font-section-sub-header-small-home">
                Relevant answers and zero hallucinations
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                RAG or Knowledge AI pulls information from third-party resources
                to give intent-based and contextual custom responses. This
                reduces the chance of{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-security-service-desk">
                  LLM hallucinating
                </a>{" "}
                due to a lack of sufficient proprietary data. RAG leverages
                semantic search to help employees get accurate and relevant
                answers.
              </p>

              <h3 className="font-section-sub-header-small-home">
                Increased agent efficiency
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide">
                  Service desk agents
                </a>{" "}
                leverage RAG-based chatbot support to accelerate the pace of
                service delivery for employees by using conversation history,
                AI-based suggestions, or AI summary capabilities. This allows
                agents to handle as many tickets as possible and reduce MTTR.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Enhanced reporting and analytics
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ’s Knowledge AI chatbot is highly effective for
                reporting and analytics. It enables you to harness rich data
                across various components that help you view actionable insights
                into agent performance and ticket status. So, your service desk
                agents can prepare to deliver enhanced ITSM services and elevate
                user experience.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Cost-efficiency
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Zero-wait time, zero-touch service desk, and intent-based
                answers prevent tickets from escalating to the service desk.
                Ticket counts decrease gradually, and agents can solve more
                critical tickets in less time. All these ultimately help reduce
                costs in managing service desk operations and boost business
                cost-efficiency.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section8">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Workativ X Knowledge AI: Powerful RAG systems for your private
                ChatGPT needs
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                RAG is the best approach to overcoming the limitations of the
                public version of LLM-powered GPT models or ChatGPT. The other
                way around, it helps you build your private ChatGPT, which helps
                you extend automation, reduce friction from self-service, and
                increase employee adoption for enhanced efficiency and
                productivity.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                In addition, Workativ has a flexible way for your service desk
                to leverage RAG, which helps you gain the benefits of private
                ChatGPT without any extra effort.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Build your private ChatGPT with us.{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  Schedule a demo today.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section9">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h3 className="font-section-sub-header-small">
                1. What is a private ChatGPT?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A private ChatGPT extends the limitations of the public version
                of GPT and improves LLM searches for custom responses for
                domain-specific scenarios by retrieving inputs from third-party
                resources.
              </p>
              <h3 className="font-section-sub-header-small">
                2. How can you relate a private ChatGPT to a RAG-based chatbot?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A private ChatGPT enables custom responses through integration
                with the third-party data repository. The RAG approach, or
                Retrieval Augmented Generation, enables integration with
                third-party resources, enables hybrid NLU for added LLM
                searches, and helps you build a private ChatGPT.
              </p>
              <h3 className="font-section-sub-header-small">
                3. What are the benefits of a private ChatGPT for employee
                support?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Service desk managers or IT leaders can benefit from leveraging
                a private ChatGPT, which ensures relevant answers, zero
                hallucinations, increased agent efficiency, empowered employees,
                etc.
              </p>
              <h3 className="font-section-sub-header-small">
                4. How does Workativ help you build a private ChatGPT?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ provides a no-code RAG-based platform or hybrid NLU,
                Knowledge AI, to help you build your private ChatGPT. Knowledge
                AI has an easy-to-use interface for integrating your third-party
                resources and intranet or company website to enhance custom
                capability and the power of LLM searches for user queries.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. What is a private ChatGPT?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. What is self-service for employee support?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. What is a private ChatGPT for self-service?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. How can you leverage the ability of a private ChatGPT for
                    employee self-service?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. How can a private ChatGPT help with self-service?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. How does Workativ help you build a private ChatGPT?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. How does Workativ empower employees with a private
                    ChatGPT?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section8"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section8"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section8")}
                  >
                    8. Workativ X Knowledge AI: Powerful RAG systems for your
                    private ChatGPT needs
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section9"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section9"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section9")}
                  >
                    9. FAQs
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://www.youtube.com/watch?v=04Vqq45hPOA">
                  ChatGPT
                </a>{" "}
                is extremely powerful when it comes to increasing employee
                support productivity through self-service.
              </p>{" "}
              <p className="font-section-normal-text-testimonials line-height-18">
                If we look into its fundamental capabilities, ChatGPT can help
                beef up{" "}
                <a href="https://workativ.com/conversational-ai-platform/ai-self-service-chatbot">
                  self-service for employee support
                </a>{" "}
                by
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Generating answers for common queries from the world of
                  knowledge
                </li>
                <li className="font-section-normal-text-testimonials">
                  Crafting responses using NLP prompts
                </li>
                <li className="font-section-normal-text-testimonials">
                  Simplifying the understanding of critical support queries
                </li>
                <li className="font-section-normal-text-testimonials">
                  Creating data analytics reports
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                The only setback is the outcome, which is quite generic if you
                work with the public version of ChatGPT.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, you can easily get the benefits that of ChatGPT while
                enjoying more personalized capacity and security by leveraging a
                Private ChatGPT.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With Private ChatGPT, you can easily elevate the typical
                self-service capacity for employee support and empower your
                employees to work autonomously.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                You already learned about ChatGPT—but Private ChatGPT may seem
                new to you.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We’ll learn in this article,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  What is a private ChatGPT?
                </li>
                <li className="font-section-normal-text-testimonials">
                  How can a private ChatGPT help boost self-service for employee
                  support?
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Keep reading, and we’ll explore more sides of a private ChatGPT
                for self-service.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is a private ChatGPT?
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="Private GPT or ChatGPT for employee support "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                A private ChatGPT is a GPT-based chatbot or chat interface that
                exhibits ChatGPT-like qualities to provide personal or
                tailor-made solutions to users unavailable with public
                solutions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                More about a private ChatGPT—
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Private ChatGPT leverages LLMs trained on internal knowledge
                  repositories or corpora of datasets.
                </li>
                <li className="font-section-normal-text-testimonials">
                  It is designed with{" "}
                  <a href="https://workativ.com/security">
                    critical security features.
                  </a>
                </li>
                <li className="font-section-normal-text-testimonials">
                  It can generate domain-specific answers and solve problems at
                  scale, including general answers.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Check the image below for an example:
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="domain-specific answers for employees on Private GPT or ChatGPT"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                When asked about the salary breakup, a private ChatGPT can give
                you straightforward answers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is because a private ChatGPT has access to internal HR
                systems and other company-wide systems that help retrieve
                specific answers.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="answers for traditional search on off-the-shelf GPT interfaces"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                On the other hand, if you ask the public version of ChatGPT,
                which has cut-off knowledge of up to September 2021, you can
                only get results similar to those of a search engine.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It means you need to provide all the critical data—a manual
                effort that is time-consuming and irritating.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Usually, your employees can call your HR to get full details.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is where a private ChatGPT comes in and provides
                tailor-made solutions to reduce manual dependency and
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-automation-virtual-employee-service-desk">
                  {" "}
                  increase self-service capability
                </a>
                , empowering your employees.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is self-service for employee support?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Self-service is an autonomous capability for customer or
                employee support. It allows users or employees to access
                knowledge bases or predefined FAQs to seek answers to their
                specific questions and solve problems independently.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The key objective of the self-service chatbot for employee
                support is to —
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Enable knowledge discovery
                </li>
                <li className="font-section-normal-text-testimonials">
                  Free agents of repetitive tasks
                </li>
                <li className="font-section-normal-text-testimonials">
                  Solve common problems at scale
                </li>
                <li className="font-section-normal-text-testimonials">
                  Reduce MTTR
                </li>
                <li className="font-section-normal-text-testimonials">
                  Reduce agent dependency
                </li>
                <li className="font-section-normal-text-testimonials">
                  Improve ROI
                </li>
                <li className="font-section-normal-text-testimonials">
                  Drive cost efficiency
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, self-service built with predefined knowledge articles
                or FAQs can often fall short of employee support expectations.
                As a result, the shift-left strategy may take a big hit. This
                would mean agent involvement would rise, and tickets would
                remain in the queue only to impact user experiences.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In contrast, a private ChatGPT can empower your employees and
                boost collaboration and efficiency for self-service.{" "}
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is a private ChatGPT for self-service?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Turning your internal knowledge bases or articles into
                ChatGPT-like abilities with the power of LLM that helps you
                generate Generative AI responses for user queries and solve
                problems autonomously is a private ChatGPT for self-service.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With a private ChatGPT, you extend the automation of
                self-service and gain more capabilities than the public version.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A private ChatGPT is effective in several ways across
                self-service due to the following reasons —
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Unique responses for new ITSM scenarios
                </li>
                <li className="font-section-normal-text-testimonials">
                  Faster NLP processing to enable rapid knowledge discovery
                </li>
                <li className="font-section-normal-text-testimonials">
                  Domain-specific responses to address company-wide issues
                </li>
                <li className="font-section-normal-text-testimonials">
                  Intelligent fallback for agent handover
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                A private ChatGPT can be very helpful for any business looking
                to leverage personalized or tailor-made solutions.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How can you leverage the ability of a private ChatGPT for
                employee self-service?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                The perspective is that if your user asks a question,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-future-enterprise-knowledge-search">
                  your LLM
                </a>{" "}
                must contain relevant document text to answer it.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With any public version of the GPT model or ChatGPT, you can
                only access the world of knowledge or the Internet for a certain
                period.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A generic answer may not suffice. Employees need relevant
                answers to address a specific challenge related to HR or IT.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Integrating your LLM models or ChatGPT with the RAG system
                easily allows you to gain the capability of a private ChatGPT.
              </p>

              <h3 className="font-section-sub-header-small-home">
                What is RAG?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                RAG is an approach that allows users to search on a third-party
                resource within a Generative AI environment yet ensures the
                relevancy, accuracy, and context of an answer.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/rag-user-support">
                  Retrieval Augmented Generation, or RAG,
                </a>{" "}
                gives LLM the additional power to retrieve relevant answers for
                a question input through a semantic search.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It helps provide personalized responses and improves
                self-service adoption and engagement for employee support.
              </p>

              <h3 className="font-section-sub-header-small-home">
                How does RAG work to build a private ChatGPT?
              </h3>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="Private GPT built on RAG for employee support "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                RAG or Retrieval Augmented Generation can work in the following
                ways:
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  RAG allows building a{" "}
                  <a href="https://workativ.com/conversational-ai-platform/conversational-ai">
                    hybrid NLU system,
                  </a>{" "}
                  making it easy for the Generative AI interface to augment LLM
                  searches by pulling up information from existing LLM databases
                  and third-party or external resources.
                </li>
                <li className="font-section-normal-text-testimonials">
                  RAG needs LLM to use vector databases to break down inputs
                  into embeddings or numeric representations.
                </li>
                <li className="font-section-normal-text-testimonials">
                  LLM searches look for answers from the LLM databases.
                </li>
                <li className="font-section-normal-text-testimonials">
                  When not found, RAG helps with semantic search and retrieves
                  custom responses from external sources for LLM.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                When integrated with RAG or hybrid NLU, you can transform the
                public version of GPT or ChatGPT into a private ChatGPT to
                address unique self-service problems and reduce{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/helpdesk-problems-solutions">
                  service desk challenges.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How can a private ChatGPT help with self-service?
              </h2>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="Private GPT vs. traditional chatbots"
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                Unlike ChatGPT or over-the-shelf GPT models, a private ChatGPT
                is designed to answer every unique question and elevate agent
                performance through enhanced productivity and efficiency.
              </p>
              <h3 className="font-section-sub-header-small-home">
                1. Contextual responses
              </h3>

              <p class="font-section-normal-text-testimonials line-height-18">
                Suppose you use a typical chatbot for common questions and
                answers. You can get answers based on keyword matching. This can
                create a negative user experience, for pre-defined answers can
                be repeated.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                A private ChatGPT uses RAG to leverage semantic search. This
                helps find information based on user intent in the LLM articles
                and external databases, boosting the relevance of responses.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                So, if your employee asks, “This is not the right laptop?”. A
                private ChatGPT fetches information from ERP systems and
                provides a satisfactory answer.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="Private GPT to improve fulfillment delivery for agents in service desk "
              />
              <h3 className="font-section-sub-header-small-home">
                2. Consolidated responses
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                What happens with a regular chatbot is that it fetches an entire
                article only to increase your effort. You must read the entire
                article, find the answer, and solve a problem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A private ChatGPT can access the proprietary data repository and
                inherent LLM summarization capability. It can give relevant
                answers to company-specific problems and reduce unnecessary
                efforts required to pull the right answer for a specific problem
                through the summarization capability.
              </p>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="Private GPT to simplify coordination and responses "
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                If your employee seeks help fixing a printer problem with a
                particular company product, a private ChatGPT can instantly
                assist.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Your employee can get summarized tips, including an article that
                contains all critical information about fixing a printer of a
                particular model.
              </p>
              <h3 className="font-section-sub-header-small-home">
                3. Personalized responses
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Ask a chatbot or ChatGPT about personal questions. It rarely can
                give answers. It can repeat ‘rephrase your queries ‘ or give a
                generic answer.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                However, a private GPT or ChatGPT can make it quite easy for
                your employees and increase their satisfaction by providing
                personalized responses.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                As RAG provides LLM searches using third-party resources or
                hybrid NLU, every employee can get personalized answers.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                For example, if you ask for benefits enrollment, it can give
                concise and personalized answers without providing an answer
                that requires further calculations.
              </p>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="personalized responses on Private GPT or ChatGPT for employee support "
              />
              <h3 className="font-section-sub-header-small-home">
                4. AI suggestions
              </h3>
              <img
                src={section_9}
                className="blog_image_top_bt"
                alt="Private GPT makes response generation easy "
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                Service desk agents can have extended flexibility and automation
                to accelerate the pace of responses. With a typical chatbot,
                employee support can suffer, as employees need better English
                knowledge to craft a message. This can lead to confusion and a
                vague understanding of the solution.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                A private ChatGPT can learn about internal{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide">
                  ITSM
                </a>{" "}
                interactions and activities, using content generation capability
                to provide accurate and relevant answers.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                For example, suppose an employee gets an agent call regarding
                software installs. In that case, the agent can craft meaningful
                messages and help appropriately despite being new and having
                limited capacity to craft messages.
              </p>
              <h3 className="font-section-sub-header-small-home">
                5. AI summary
              </h3>
              <img
                src={section_10}
                className="blog_image_top_bt"
                alt="Private GPT to provide chat summaries for agents"
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                Agents often craft a summary of an incident for a closed ticket.
                This task may appear challenging for anyone. They often prefer
                overlooking the responsibility, leading to a fiasco when needed
                to pull data and create a report.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                It is easy for agents to craft a summary as a private ChatGPT,
                enabling them to use internal ITSM data for actions to mitigate
                a problem.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                A typical chatbot can lack this feature. Even if a ChatGPT can
                help with summarization, some information may be irrelevant.
              </p>
              <h3 className="font-section-sub-header-small-home">
                6. Ticket triage
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                It is only possible for your internal service desk team to learn
                who is eligible to handle a particle case. When RAG has access
                to that resource, your Private ChatGPT can allocate a particular
                employee support case to the right agent when needed.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                An LLM inside a Generative AI interface uses RAG and LLM to
                build predictive insights and determine who can handle a case
                depending on previous history and actions taken.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                However, a public version of the GPT model cannot work to this
                scale due to limited data exposure, giving only a generic
                solution.
              </p>
              <h3 className="font-section-sub-header-small-home">
                7. Accurate and relevant answers
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Employees are more independent in solving IT and HR-related
                queries rather than choosing to go to agents. The Retrieval
                Augmented Generation, or RAG, helps boost LLM searches and fetch
                relevant answers, which offer intent, context, and
                disambiguation. This can effectively remove hallucinations,
                which are familiar with the public version of the GPT model, as
                they can make up responses in the absence of sufficient data.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Employees can solve problems faster and increase productivity
                with an increased relevancy rate.
              </p>

              <CaseStudyCtaGoTo colorKey="peach" textColor="blue" />
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How does Workativ help you build a private ChatGPT?
              </h2>
              <img
                src={section_11}
                className="blog_image_top_bt"
                alt="Private GPT or ChatGPT for employee experience"
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ
                </a>{" "}
                is known to make employee support seamless with its LLM-powered
                conversational AI platform.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                In addition, Workativ offers an excellent opportunity to build a
                private ChatGPT effortlessly using its Knowledge AI feature.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI
                </a>{" "}
                is similar to RAG systems, allowing you to access third-party
                resources to extend LLM searches.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ is a no-code{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  SaaS-based conversational AI platform
                </a>{" "}
                that can easily be used to build your Private ChatGPT for HR and
                IT support.
              </p>
              <h3 className="font-section-sub-header-bold">
                Follow some simple steps to build your private ChatGPT with
                Workativ:
              </h3>
              <h3 className="font-section-sub-header-small-home">
                Step 1: Use existing conversations to add Knowledge AI
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Choose an existing conversation flow
                </li>
                <li className="font-section-normal-text-testimonials">
                  Click the Arrow sign next to a card
                </li>
                <li className="font-section-normal-text-testimonials">
                  Find a drop-down menu
                </li>
                <li className="font-section-normal-text-testimonials">
                  Select Knowledge AI
                </li>
                <li className="font-section-normal-text-testimonials">
                  Supply details to the box
                </li>
                <li className="font-section-normal-text-testimonials">
                  Save it
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                Your existing chat conversation can leverage the power of
                Knowledge AI or private ChatGPT-like searches and boost employee
                support.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                To learn more about how you can create a Private ChatGPT for
                employee support,{" "}
                <a href="https://workativ.com/contact-us">contact us</a> at
                Workativ.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Step 2: Build Knowledge AI conversations from scratch
              </h3>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Go to chatbot builder inside Workativ
                </li>
                <li className="font-section-normal-text-testimonials">
                  Select Knowledge AI from the menu header
                </li>
                <li className="font-section-normal-text-testimonials">
                  Select third-party resources, i.e., SharePoint, Drive,
                  DropBox, etc
                </li>
                <li className="font-section-normal-text-testimonials">
                  Supply necessary information and get started
                </li>
                <li className="font-section-normal-text-testimonials">
                  Prepare user query
                </li>
                <li className="font-section-normal-text-testimonials">
                  Customize your workflows
                </li>
                <li className="font-section-normal-text-testimonials">
                  Add human touch
                </li>
                <li className="font-section-normal-text-testimonials">
                  Deploy your Knowledge AI or private ChatGPT bot in MS Teams,
                  Slack, or anywhere
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                Building a private ChatGPT is not hard work. You can use a
                zero-code platform and implement Knowledge AI search for your
                employees.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How does Workativ empower employees with a private ChatGPT?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                As you build your private ChatGPT or Knowledge AI chatbot,
                things can work in your favor, and you will have amazing
                benefits for HR and IT support.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Zero-wait time
              </h3>

              <p class="font-section-normal-text-testimonials line-height-18">
                Private ChatGPT or Knowledge AI for HR chatbot or IT support
                chatbot can boost collaborations and communications by turning
                your knowledge articles into ChatGPT-like responses. Your
                employees can increase their response time for problem
                resolutions and enjoy{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-employee-support-24-7#:~:text=Simultaneously%2C%20Workativ%20is%20the%20right,drive%20towards%20zero%20wait%20time.">
                  zero wait time
                </a>{" "}
                for service desk queries.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Zero-touch service desks
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ’s Knowledge AI or Private ChatGPT empowers employees,
                including service desk agents. With LLM-powered knowledge
                articles, your employees find answers rapidly, seeking agents’
                help in rare cases. Employees autonomously solve their problems,
                freeing agents to drive towards{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-user-support-zero-touch">
                  zero-touch service desks.{" "}
                </a>
              </p>

              <h3 className="font-section-sub-header-small-home">
                Relevant answers and zero hallucinations
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                RAG or Knowledge AI pulls information from third-party resources
                to give intent-based and contextual custom responses. This
                reduces the chance of{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-security-service-desk">
                  LLM hallucinating
                </a>{" "}
                due to a lack of sufficient proprietary data. RAG leverages
                semantic search to help employees get accurate and relevant
                answers.
              </p>

              <h3 className="font-section-sub-header-small-home">
                Increased agent efficiency
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide">
                  Service desk agents
                </a>{" "}
                leverage RAG-based chatbot support to accelerate the pace of
                service delivery for employees by using conversation history,
                AI-based suggestions, or AI summary capabilities. This allows
                agents to handle as many tickets as possible and reduce MTTR.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Enhanced reporting and analytics
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ’s Knowledge AI chatbot is highly effective for
                reporting and analytics. It enables you to harness rich data
                across various components that help you view actionable insights
                into agent performance and ticket status. So, your service desk
                agents can prepare to deliver enhanced ITSM services and elevate
                user experience.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Cost-efficiency
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Zero-wait time, zero-touch service desk, and intent-based
                answers prevent tickets from escalating to the service desk.
                Ticket counts decrease gradually, and agents can solve more
                critical tickets in less time. All these ultimately help reduce
                costs in managing service desk operations and boost business
                cost-efficiency.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section8">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Workativ X Knowledge AI: Powerful RAG systems for your private
                ChatGPT needs
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                RAG is the best approach to overcoming the limitations of the
                public version of LLM-powered GPT models or ChatGPT. The other
                way around, it helps you build your private ChatGPT, which helps
                you extend automation, reduce friction from self-service, and
                increase employee adoption for enhanced efficiency and
                productivity.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                In addition, Workativ has a flexible way for your service desk
                to leverage RAG, which helps you gain the benefits of private
                ChatGPT without any extra effort.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Build your private ChatGPT with us.{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  Schedule a demo today.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section9">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h3 className="font-section-sub-header-small">
                1. What is a private ChatGPT?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A private ChatGPT extends the limitations of the public version
                of GPT and improves LLM searches for custom responses for
                domain-specific scenarios by retrieving inputs from third-party
                resources.
              </p>
              <h3 className="font-section-sub-header-small">
                2. How can you relate a private ChatGPT to a RAG-based chatbot?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A private ChatGPT enables custom responses through integration
                with the third-party data repository. The RAG approach, or
                Retrieval Augmented Generation, enables integration with
                third-party resources, enables hybrid NLU for added LLM
                searches, and helps you build a private ChatGPT.
              </p>
              <h3 className="font-section-sub-header-small">
                3. What are the benefits of a private ChatGPT for employee
                support?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Service desk managers or IT leaders can benefit from leveraging
                a private ChatGPT, which ensures relevant answers, zero
                hallucinations, increased agent efficiency, empowered employees,
                etc.
              </p>
              <h3 className="font-section-sub-header-small">
                4. How does Workativ help you build a private ChatGPT?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ provides a no-code RAG-based platform or hybrid NLU,
                Knowledge AI, to help you build your private ChatGPT. Knowledge
                AI has an easy-to-use interface for integrating your third-party
                resources and intranet or company website to enhance custom
                capability and the power of LLM searches for user queries.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/top-itsm-trends-2023"
              className="font-section-normal-text-testimonials"
            >
              TOP ITSM Trends 2023: Preparing for the Future
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide"
              className="font-section-normal-text-testimonials"
            >
              ITSM CHATBOT- Use Cases and Benefits Explained
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/service-desk-automation"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Service Desk Automation: ELEVATE YOUR IT SUPPORT EFFICIENCY
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
